import {createContext, useState, useEffect} from 'react';
import Axios from "axios";
import _ from "lodash";

const CPHandlerContext = createContext({})

const CPHandlerProvider = (props) => {
    
    const [gbData, setGbData] = useState({})
    const [urlParams, setUrlParams] = useState({})
    const [userData, setUserData] = useState(0);
	
    useEffect(() => {

		let url_params = new URL(window.location.href);
		
		const loginData = JSON.parse(localStorage.getItem('userData'));

		setUrlParams(url_params);
		setUserData(loginData.data);
		
    },[])

    useEffect(() => {
		//console.log(urlParams);
		//console.log(userData);
        setGbData({
            urlParams: urlParams,
            userData: userData
        })
		
    }, [urlParams, userData]);

    return (
        <CPHandlerContext.Provider value={gbData}>
            {props.children}
        </CPHandlerContext.Provider>
    );
}

export {CPHandlerProvider,CPHandlerContext};